import type { Coin } from "@keplr-wallet/types";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import type { GetTxByHashResponse, GetTxByHashResponseAPI } from "shared/api/transactions/types";

import {
  useTransactionByHashQuery,
  useTransactionByHashQueryAPI,
} from "shared/api/transactions/useTransactionByHashQuery";
import { base64ToString } from "shared/helpers/base64ToString";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Card } from "shared/ui/Card";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { EllipsisText } from "shared/ui/EllipsisText";
import { HexDecodeText } from "shared/ui/HexDecodeText";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Tabs } from "shared/ui/Tabs";
import { toaster } from "shared/ui/Toast";

const rowStyles =
  "grid grid-cols-[minmax(100px,auto)_1fr_1fr_1fr_1fr_1fr] border-b border-b-clay-900 py-3 text-clay-300";
interface TokenPrice {
  input_price: Coin;
  output_price: Coin;
}
const getSectionTitleByKey = (key: string) => {
  if (key === "txhash") {
    return "Tx Hash";
  }

  return key.replace(/[@_]/, " ").trim();
};

const checkIsAmountArray = (value: unknown): value is Coin[] => {
  const hasAmount = !!(value as Coin[])?.[0]?.amount;

  return hasAmount;
};

const checkIsAmount = (value: unknown): value is Coin => {
  const hasAmount = !!(value as Coin)?.amount;

  return hasAmount;
};
const checkIsTokenPrice = (value: unknown): value is TokenPrice => {
  return (
    typeof value === "object" &&
    value !== null &&
    "input_price" in value &&
    "output_price" in value &&
    typeof (value as TokenPrice).input_price.amount === "string" &&
    typeof (value as TokenPrice).output_price.amount === "string"
  );
};

export const Transaction = () => {
  const navigate = useNavigate();
  const { chainId, hash } = useParams<{ chainId: string; hash: string }>();
  const { data: dataIcd, status: statusIcd } = useTransactionByHashQuery(
    { hash: hash! },
    { enabled: !!hash },
  );
  const { data: dataAPI, isPending: isPendingAPI } = useTransactionByHashQueryAPI(
    { hash: hash! },
    { enabled: statusIcd === "error" },
  );
  const isPending = statusIcd === "pending" || (statusIcd === "error" && isPendingAPI);
  const data = statusIcd === "success" ? dataIcd : dataAPI;
  const { tx, tx_response } = data || {};

  const isSuccessful = !!tx_response;
  const messages =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tx?.body?.messages.map((msg: any) => {
      if (msg.packet?.data) {
        return {
          ...msg,
          message: base64ToString(msg.packet.data),
        };
      }
      return msg;
    }) || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const feeData = (tx as any)?.["auth_info"]?.fee?.amount[0];
  const sm = useMinWidthMediaQuery("sm");

  return (
    <AnimateRoute className="relative flex flex-col overflow-hidden p-0 pb-5 pt-2">
      <div className="mx-8 flex items-center justify-between max-md:ml-4 max-md:flex-col max-md:items-baseline max-md:pt-0">
        <div
          className="flex items-center gap-6 text-2xl font-light text-white max-md:gap-3 max-md:text-xl"
          onClick={() => navigate(`/`)}
        >
          <Icon
            className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
            name="arrowLeft"
          />
          Transaction Details
        </div>

        <div className="flex items-center gap-2 rounded-lg bg-clay-900 px-4 py-1 max-md:mt-5 max-md:w-full max-md:justify-between max-md:pr-0 sm:gap-4">
          <div className="whitespace-nowrap text-white">Tx Hash</div>
          <div className="col-span-5 text-clay-300 max-md:min-w-[200px]">
            {/* {hash} */}
            {sm ? hash : <EllipsisStatic className="min-w-[200px]" text={hash || ""} />}
          </div>
          <CopyToClipboard onCopy={() => toaster.success("Copied")} text={hash || ""}>
            <div className="inline-block cursor-pointer py-2 pr-4">
              <Icon className="size-4 cursor-pointer text-clay-300 hover:text-white" name="copy" />
            </div>
          </CopyToClipboard>
        </div>
      </div>

      <div className="my-8 border-t border-clay-900 max-md:my-6"></div>

      {isPending ? (
        <div className="flex h-full items-center justify-center">
          <Spinner className="size-8" />
        </div>
      ) : !data ? (
        <div className="flex w-full justify-center text-3xl font-light text-white">
          Transaction Not Found
        </div>
      ) : isSuccessful ? (
        <div className="h-full">
          <Card className="mb-6 px-64 max-md:px-0">
            <Card.Content className="px-44 max-md:px-10">
              <h1 className="mb-3 text-3xl text-white">Summary</h1>

              {isPending ? (
                <div className="flex flex-col items-center justify-center py-8">
                  <Spinner className="size-6" />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className={rowStyles}>
                    <div className="text-white">Height</div>
                    <div className="col-span-5 text-clay-300">{tx_response?.height}</div>
                  </div>
                  <div className={twMerge(rowStyles, "py-2")}>
                    <div className="flex items-center text-white">Status</div>
                    <div
                      className={twMerge(
                        "col-span-5 w-fit rounded-md bg-pink-500/20 px-2 py-1 text-pink-500",
                        isSuccessful && "bg-primary-900/20 text-primary-900",
                      )}
                    >
                      {isSuccessful ? "Success" : "Failed"}
                    </div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Time</div>
                    <div className="col-span-5 text-clay-300">
                      {tx_response?.timestamp
                        ? `${DateTime.fromISO(tx_response.timestamp).toFormat("yyyy-MM-dd hh:mm:ss")} (${timeAgo(DateTime.fromISO(tx_response.timestamp))})`
                        : "-"}
                    </div>
                  </div>

                  <div className={rowStyles}>
                    <div className="text-white">Gas</div>
                    <div className="col-span-5 text-clay-300">
                      {tx_response?.gas_used || "-"} / {tx_response?.gas_wanted || "-"}
                    </div>
                  </div>

                  <div className={rowStyles}>
                    <div className="text-white">Fee</div>
                    <div className="col-span-5 text-clay-300">
                      {feeData?.amount} {feeData?.denom}
                    </div>
                  </div>

                  <div className={twMerge(rowStyles, "border-b-transparent pb-0")}>
                    <div className="text-white">Memo</div>
                    <div className="col-span-5 text-clay-300">{tx?.body.memo || "Not defined"}</div>
                  </div>
                </div>
              )}
            </Card.Content>
          </Card>

          {messages.length > 0 && (
            <Card className="px-64 max-md:px-0">
              <Card.Content className="border-t px-44 pr-0 max-md:px-10">
                <h1 className="mb-3 text-3xl text-white">Messages ({messages.length})</h1>

                {messages.map((msg, i) => {
                  return (
                    <div className="flex flex-col" key={i}>
                      <h2 className="pb-1 pt-4 font-medium text-white">Message {i + 1}</h2>

                      {Object.entries(msg).map(([key, value]) => {
                        const isStr = typeof value === "string";
                        const isAccount = key === "account";
                        const isAmountArray = checkIsAmountArray(value);
                        const isAmount = checkIsAmount(value);
                        const isVrf = key === "vrf";
                        const isTokenPrice = key === "token_price" && checkIsTokenPrice(value);

                        return (
                          <div className={rowStyles} key={key}>
                            <div className="flex items-center capitalize text-white">
                              {getSectionTitleByKey(key)}
                            </div>
                            {isStr &&
                              (isAccount ? (
                                <div
                                  className="col-span-5 cursor-pointer font-medium text-tusk-100 transition-colors hover:text-tusk-300"
                                  onClick={() => {
                                    navigate(`/${chainId}/address/${value}`);
                                  }}
                                >
                                  <EllipsisText text={value} />
                                </div>
                              ) : (
                                <div className="col-span-5">
                                  <EllipsisText text={value} />
                                </div>
                              ))}
                            {isAmountArray && (
                              <div className="col-span-5">
                                {value?.[0].amount}
                                {value?.[0].denom}
                              </div>
                            )}
                            {isAmount && (
                              <div className="col-span-5">
                                {value?.amount}
                                {value?.denom}
                              </div>
                            )}
                            {isTokenPrice && (
                              <div className="col-span-5">
                                <div>
                                  Input Price: {value.input_price.amount} {value.input_price.denom}
                                </div>
                                <div>
                                  Output Price: {value.output_price.amount}{" "}
                                  {value.output_price.denom}
                                </div>
                              </div>
                            )}
                            {isVrf && (
                              <div className="col-span-5">
                                <Tabs.Root
                                  defaultValue="seed"
                                  tabs={
                                    <Tabs.List className="mb-1.5">
                                      <Tabs.Trigger value="seed">Seed</Tabs.Trigger>
                                      <Tabs.Trigger value="proof">Proof</Tabs.Trigger>
                                      <Tabs.Trigger value="hash_random">Hash Random</Tabs.Trigger>
                                    </Tabs.List>
                                  }
                                >
                                  <Tabs.Content className="w-full" value="seed">
                                    <HexDecodeText value={msg.vrf.seed} />
                                  </Tabs.Content>
                                  <Tabs.Content className="w-full truncate" value="proof">
                                    <EllipsisStatic copyable text={msg.vrf.proof} />
                                  </Tabs.Content>
                                  <Tabs.Content className="w-full" value="hash_random">
                                    <HexDecodeText value={msg.vrf.hash_random} />
                                  </Tabs.Content>
                                </Tabs.Root>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Card.Content>
            </Card>
          )}
        </div>
      ) : (
        <div className="flex w-full justify-center text-3xl font-light text-white">
          Transaction Not Found
        </div>
      )}
    </AnimateRoute>
  );
};
