import type { ComponentProps, ReactElement } from "react";
import { createContext, forwardRef, useContext, useId, useState } from "react";

import * as RTabs from "@radix-ui/react-tabs";
import { AnimatePresence } from "framer-motion";
import { twMerge } from "tailwind-merge";

const LayoutContext = createContext<null | string>(null);
const ActiveTabContext = createContext<null | string>(null);

const useActiveTab = () => {
  const context = useContext(ActiveTabContext);

  return context;
};

const List = forwardRef<HTMLDivElement, ComponentProps<typeof RTabs.List>>(
  ({ children, className, ...props }, forwardRef) => {
    const layoutId = useId();

    return (
      <LayoutContext.Provider value={layoutId}>
        <RTabs.List {...props} className={twMerge("relative flex", className)} ref={forwardRef}>
          {children}
        </RTabs.List>
      </LayoutContext.Provider>
    );
  },
);

const Trigger = forwardRef<HTMLButtonElement, ComponentProps<typeof RTabs.Trigger>>(
  ({ children, className, value, ...props }, forwardRef) => {
    const activeTab = useActiveTab();

    const isActive = activeTab === value;

    return (
      <RTabs.Trigger
        {...props}
        aria-selected={isActive}
        className={twMerge(
          "relative flex py-1 first:rounded-l-md last:rounded-r-md",
          "disabled:cursor-not-allowed disabled:text-corduroy-500",
          className,
        )}
        ref={forwardRef}
        value={value}
      >
        <div
          className={twMerge(
            "flex h-7 items-center justify-center rounded-[0.1875rem] p-2 text-sm text-clay-380",
            isActive && "text-white",
          )}
        >
          {children}
          {isActive && (
            <span className="absolute inset-x-0 bottom-1 h-0.5 translate-y-0 bg-purple-800"></span>
          )}
        </div>
      </RTabs.Trigger>
    );
  },
);

const Content = RTabs.Content;

type RootProps<Tab extends string> = {
  defaultValue?: Tab;
  onValueChange?: (tab: Tab) => void;
  tabs: ReactElement;
  value?: Tab;
};

const Root = <Tab extends string>({
  children,
  tabs,
  ...props
}: Omit<ComponentProps<typeof RTabs.Root>, keyof RootProps<Tab>> & RootProps<Tab>) => {
  const [activeTab, setActiveTab] = useState(props.defaultValue);

  const isControlled = typeof props.onValueChange === "function" && typeof props.value === "string";

  const value = isControlled ? props.value : activeTab;
  const onTabChange = isControlled ? props.onValueChange : setActiveTab;

  return (
    <ActiveTabContext.Provider value={value || null}>
      <AnimatePresence>
        <RTabs.Root {...props} onValueChange={(tab) => onTabChange?.(tab as Tab)} value={value}>
          {tabs}

          {children}
        </RTabs.Root>
      </AnimatePresence>
    </ActiveTabContext.Provider>
  );
};

export const Tabs = {
  Content,
  List,
  Root,
  Trigger,
};
