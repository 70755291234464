import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useAccountTxsQuery";
import type { TableColumn } from "shared/ui/Table";

import { getTxMessage } from "shared/helpers/getTxMessage";
import { Icon } from "shared/ui/Icon";

export const useTxsColumns = () => {
  const navigate = useNavigate();
  const { chainId } = useParams();

  return [
    {
      key: "height",
      renderTd: (data) => {
        return <div>{data.tx_response.height}</div>;
      },
      title: "Block Height",
    },
    {
      key: "txhash",
      renderTd: (data) => {
        const txhash = data.tx_response.txhash;
        return (
          <div
            className="cursor-pointer text-tusk-100 transition-colors"
            onClick={() => navigate(`/${chainId}/transactions/${txhash}`)}
          >
            {txhash.slice(0, 10) + "..." + txhash.slice(-6)}
          </div>
        );
      },
      title: "Tx Hash",
    },
    {
      key: "tx",
      renderTd: (data) => {
        // const messages = data.tx.body.messages[0];

        return (
          <div className="flex min-w-fit items-center gap-1">
            {getTxMessage(data.tx.body.messages)}
          </div>
        );
      },
      title: "Messages",
    },
    {
      key: "timestamp",
      renderTd: (data) => {
        return (
          <div className="whitespace-nowrap">
            {data.tx_response.timestamp
              ? DateTime.fromISO(data.tx_response.timestamp).toFormat("yyyy-MM-dd hh:mm:ss")
              : "-"}
          </div>
        );
      },
      title: "Timestamp",
    },
  ] as TableColumn<keyof TransactionQueryResponse, TransactionQueryResponse>[];
};
