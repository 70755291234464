import { useLayoutEffect, useRef, useState } from "react";

import { twMerge } from "tailwind-merge";

import { toaster } from "shared/ui/Toast";

interface EllipsisTextProps {
  className?: string;
  copyable?: boolean;
  text: string;
}

export const EllipsisStatic = ({ className, copyable = false, text }: EllipsisTextProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayText, setDisplayText] = useState("");

  const handleCopy = () => {
    if (!copyable) return;
    navigator.clipboard.writeText(text).then(() => {
      toaster.success("Copied to clipboard");
    });
  };

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const measureTextWidth = (str: string) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return 0;
      ctx.font = getComputedStyle(container).font;
      return ctx.measureText(str).width;
    };

    const calculateCharsToShow = () => {
      const style = getComputedStyle(container);
      const paddingLeft = parseFloat(style.paddingLeft);
      const paddingRight = parseFloat(style.paddingRight);
      const availableWidth =
        container.getBoundingClientRect().width - paddingLeft - paddingRight - 3;

      let left = 6;
      let right = Math.floor(text.length / 2);
      let bestFit = left;

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const testText = `${text.slice(0, mid)}...${text.slice(-mid)}`;
        const width = measureTextWidth(testText);

        if (width <= availableWidth) {
          bestFit = mid;
          left = mid + 1;
        } else {
          right = mid - 1;
        }
      }
      return bestFit;
    };

    const updateDisplay = () => {
      const chars = calculateCharsToShow();
      if (text.length <= chars * 2 + 3) {
        setDisplayText(text);
      } else {
        const start = text.slice(0, chars);
        const end = text.slice(-chars);
        setDisplayText(`${start}...${end}`);
      }
    };

    updateDisplay();

    const resizeObserver = new ResizeObserver(updateDisplay);
    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [text]);

  return (
    <div
      className={twMerge(
        "overflow-hidden whitespace-nowrap",
        copyable && "cursor-pointer",
        className,
      )}
      onClick={handleCopy}
      ref={containerRef}
    >
      {displayText}
    </div>
  );
};
