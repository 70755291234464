import { useMemo, useState } from "react";

import { convertHash } from "shared/helpers/decode";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { toaster } from "shared/ui/Toast";

export const HashValue = ({ hash }: { hash?: string }) => {
  const [showHash, setShowHash] = useState(true);

  const decodeHash = useMemo(() => {
    return convertHash(hash);
  }, [hash]);

  const handleCopy = async () => {
    const textToCopy = showHash ? hash : decodeHash;
    if (textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        toaster.success("Copied to clipboard");
      } catch (err) {
        console.error("Failed to copy text:", err);
        toaster.error("Failed to copy");
      }
    }
  };

  return (
    <div className="flex w-full items-center justify-between text-clay-300">
      <div
        className="flex-1 cursor-pointer truncate transition-opacity duration-200 hover:opacity-80"
        onClick={handleCopy}
        title="Click to copy"
      >
        {/* {showHash ? hash : decodeHash} */}
        <EllipsisStatic className="w-full" text={showHash ? hash || "" : decodeHash || ""} />
      </div>
      {decodeHash && (
        <Icon
          className="size-[1.125rem] cursor-pointer text-tusk-100 transition-all duration-200 hover:text-tusk-300"
          name="rotate"
          onClick={() => setShowHash(!showHash)}
        />
      )}
    </div>
  );
};
