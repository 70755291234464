import { useCallback, useEffect, useRef, useState } from "react";

import { twMerge } from "tailwind-merge";

interface EllipsisTextProps {
  className?: string;
  text: string;
}

export const EllipsisText = ({ className, text }: EllipsisTextProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);
  const [displayText, setDisplayText] = useState(text);

  const updateEllipsis = useCallback(() => {
    const container = containerRef.current;
    const measure = measureRef.current;

    if (!container || !measure) return;
    // Display the complete text directly first to avoid blank spaces
    setDisplayText(text);

    requestAnimationFrame(() => {
      const containerWidth = container.clientWidth;
      measure.innerText = text;
      const fullWidth = measure.offsetWidth;

      if (fullWidth <= containerWidth) {
        setDisplayText(text);
        return;
      }

      const totalLen = text.length;
      const afterEllipsis = 5;
      let left = 0;
      let right = totalLen - afterEllipsis;
      let bestFront = 0;

      const measureText = (frontCount: number) => {
        const truncated = text.slice(0, frontCount) + "..." + text.slice(-afterEllipsis);
        measure.innerText = truncated;
        return measure.offsetWidth;
      };
      // Use requestIdleCallback to perform calculations asynchronously to avoid blocking the main thread
      const runBinarySearch = () => {
        if (left > right) {
          setDisplayText(text.slice(0, bestFront) + "..." + text.slice(-afterEllipsis));
          return;
        }

        const mid = Math.floor((left + right) / 2);
        const w = measureText(mid);

        if (w <= containerWidth) {
          bestFront = mid;
          left = mid + 1;
        } else {
          right = mid - 1;
        }
        // Let the browser continue calculating when idle
        requestIdleCallback(runBinarySearch);
      };

      requestIdleCallback(runBinarySearch);
    });
  }, [text]);

  useEffect(() => {
    updateEllipsis();
    const onResize = () => updateEllipsis();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [updateEllipsis]);

  return (
    <div className={twMerge("overflow-hidden", className)} ref={containerRef}>
      {displayText}
      <span className="invisible absolute whitespace-nowrap" ref={measureRef} />
    </div>
  );
};
