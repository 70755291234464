import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { IconName } from "shared/ui/Icon";

import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";
import { Modal } from "shared/ui/Modal";
import { toaster } from "shared/ui/Toast";

type Props = {
  className?: string;
  inheritPopoverWidth?: boolean;
  isHotKeySupported?: boolean;
};

const OPEN_SEARCH_KEYS = ["k", "meta"];

const height = /^\d+$/;
const txhash = /^[A-Z\d]{64}$/;
const addr = /^[a-z\d]+1[a-z\d]{38,58}$/;

type SearchItem = {
  description: string;
  icon: IconName;
  title: string;
};

const searchItems: SearchItem[] = [
  {
    description: "Use this command to search only in transactions",
    icon: "arrowLeftRight",
    title: "transaction",
  },
  {
    description: "Search for validators, ex. /validator nesa",
    icon: "scanEye",
    title: "validator",
  },
  { description: "Search for blocks, ex. /block 1269255", icon: "box", title: "block" },
];

export const Search = ({ className, isHotKeySupported = true }: Props) => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sm = useMinWidthMediaQuery("sm");

  useEffect(() => {
    if (!isHotKeySupported) return;
    let pressedKeys: string[] = [];

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key && OPEN_SEARCH_KEYS.includes(e.key.toLowerCase())) {
        pressedKeys = pressedKeys.includes(e.key) ? pressedKeys : [...pressedKeys, e.key];

        if (pressedKeys.length === 2) {
          setIsModalOpen(true);
          pressedKeys = [];
        }
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key && OPEN_SEARCH_KEYS.includes(e.key.toLowerCase())) {
        pressedKeys = pressedKeys.filter((key) => key !== e.key);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [isHotKeySupported]);

  const { chainId } = useParams();

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!searchValue) {
      toaster.error("No search defined");
      return;
    }

    const splitResult = searchValue.trim().split(/\s+/);

    if (splitResult.length < 1) {
      toaster.error("No search defined");
      return;
    }

    const command = splitResult[0];
    const queryValue = splitResult.length == 1 ? splitResult[0] : splitResult[1];

    // First check if command matches any predefined cases
    if (command === "/transaction" || command === "/validator" || command === "/block") {
      switch (command) {
        case "/transaction":
          if (txhash.test(queryValue)) {
            setIsModalOpen(false);
            navigate(`/${chainId}/transactions/${queryValue}`);
          } else {
            toaster.error("Given value is not a valid transaction hash");
          }
          break;

        case "/validator":
          if (addr.test(queryValue)) {
            setIsModalOpen(false);
            navigate(`/${chainId}/address/${queryValue}`);
          } else {
            toaster.error("Given value is not a valid address");
          }
          break;

        case "/block":
          if (height.test(queryValue)) {
            setIsModalOpen(false);
            navigate(`/${chainId}/blocks/${queryValue}`);
          } else {
            toaster.error("Given value is not a valid block height");
          }
          break;
      }
    } else {
      // If no command matches, try to match the queryValue against patterns
      if (txhash.test(queryValue)) {
        setIsModalOpen(false);
        navigate(`/${chainId}/transactions/${queryValue}`);
      } else if (addr.test(queryValue)) {
        setIsModalOpen(false);
        navigate(`/${chainId}/address/${queryValue}`);
      } else if (height.test(queryValue)) {
        setIsModalOpen(false);
        navigate(`/${chainId}/blocks/${queryValue}`);
      } else {
        toaster.error(
          "Given value is not a valid query command or value. Only /transaction、/validator or /block can be used, or enter a valid transaction hash, address, or block height.",
        );
      }
    }
  };

  return (
    <div>
      <div
        className="flex h-9 w-52 cursor-pointer items-center justify-between rounded-lg bg-clay-900 p-1.5 hover:bg-clay-850"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className="flex gap-2 text-sm text-clay-500">
          <Icon className="text-clay-100" name="search" />
          Search
        </div>

        <div className="flex gap-0.5">
          <div className="flex size-6 items-center justify-center rounded bg-clay-700 text-xs text-clay-100">
            ⌘
          </div>

          <div className="flex size-6 items-center justify-center rounded bg-clay-700 text-xs text-clay-100">
            K
          </div>
        </div>
      </div>

      <Modal onOpenChange={setIsModalOpen} open={isModalOpen}>
        <Modal.Content className="w-fit" dialogClassName="p-0">
          <Modal.Title className="mb-5 border-b border-clay-600 p-2">
            <form className={className} onSubmit={handleSearch}>
              <Input
                className="h-9"
                classNameInput="text-white placeholder:text-clay-500"
                classNameInputWrapper="bg-clay-900 border-0 rounded-lg px-1.5 shadow-sm"
                onChange={(e) => setSearchValue(e.target.value)}
                onFocusCapture={() => {
                  setIsModalOpen(true);
                }}
                placeholder={sm ? "Addresses, Validators, Miners..." : "Addresses..."}
                size="medium"
                startSlot={
                  <div className="mr-3 flex gap-4">
                    <Icon className="text-clay-100" name="search" />
                    <span className="text-white">Search:</span>
                  </div>
                }
                value={searchValue}
              />
            </form>
          </Modal.Title>

          <div className="px-4">
            <div className="flex flex-col gap-5 border-b border-clay-600 pb-4">
              {searchItems.map((item) => (
                <div className="flex items-center gap-4" key={item.title}>
                  <Icon
                    className="size-8 rounded-lg bg-clay-1000 p-2 text-clay-100"
                    name={item.icon}
                  />
                  <div className="w-96">
                    <div className="flex text-white">
                      <span className="text-tusk-100">/</span>
                      {item.title}
                    </div>
                    <div className="text-clay-500">{item.description}</div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="flex cursor-pointer items-center gap-2 py-4 text-sm text-clay-380"
              onClick={() => window.open("https://docs.nesa.ai/nesa", "_blank")}
            >
              Not finding something ? Check the FAQ
              <Icon
                className="size-4 cursor-pointer text-white"
                name="arrowUpRight"
                onClick={() => toaster.info("Coming soon")}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};
