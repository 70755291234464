import { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { redirect, useNavigate, useParams } from "react-router-dom";

import { BigNumber } from "bignumber.js";
import { useAccount } from "graz";
import { twMerge } from "tailwind-merge";

// import type { TxResponse } from "shared/api/Transaction/types";
import type { Delegation } from "shared/api/staking/types";
import type { TableColumn } from "shared/ui/Table";

import { TxDialog } from "features/TxDialog";
import { WalletProviderModal } from "features/WalletProviderModal";
import { useAccountInfoQuery } from "shared/api/auth/useAccountInfoQuery";
import { useBalancesQuery } from "shared/api/balances";
import { useStakingDelegationsQuery } from "shared/api/staking/useStakingDelegationsQuery";
import {
  useLastByAccountQuery,
  useLastReceivedByAccountQuery,
} from "shared/api/transactions/useAccountTxsQuery";
import { fromUnesToNes } from "shared/helpers/fromUnesToNes";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";
import { toaster } from "shared/ui/Toast";

import { useTxsColumns } from "./hooks/useTxsColumns";

const rowStyles = "grid grid-cols-[120px_repeat(5,_1fr)] border-b border-b-clay-900 py-3";

export const Address = () => {
  const navigate = useNavigate();

  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [isDelegateOpen, setIsDelegateOpen] = useState(false);

  const { address, chainId } = useParams<{ address: string; chainId: string }>();

  const account = useAccount();

  const network = getChainById(chainId);

  useEffect(() => {
    if (!address) {
      redirect("/");
    }
  }, [address]);
  //Recent Received
  const {
    data: lastReceivedData,
    fetchNextPage: fetchNextPageRecent,
    hasNextPage: hasNextPageRecent,
    isFetchingNextPage: isFetchingNextPageRecent,
    isPending: isTxsPending,
  } = useLastReceivedByAccountQuery({ address: address!, limit: 10 });
  const lastReceived = useMemo(
    () => (lastReceivedData ? lastReceivedData.pages.flatMap((page) => page) : []),
    [lastReceivedData],
  );

  //Transaction
  const {
    data: txs,
    fetchNextPage: fetchNextPageTransaction,
    hasNextPage: hasNextPageTransaction,
    isFetchingNextPage: isFetchingNextPageTransaction,
    isPending: isTxsLoading,
  } = useLastByAccountQuery({
    address: address!,
    limit: 10,
  });

  const lastTxs = useMemo(() => (txs ? txs.pages.flatMap((page) => page) : []), [txs]);

  const { data: accountInfoData, isPending: isAccountInfoPending } = useAccountInfoQuery(
    { address: address! },
    { enabled: !!address },
  );
  const { data: delegations, isPending: isDelegationsPending } = useStakingDelegationsQuery(
    { delegatorAddress: address!, network },
    { enabled: !!address },
  );

  const { data: balances, isPending: isBalancesPending } = useBalancesQuery({
    address: address,
    network,
  });

  const sumBalances = useMemo(() => {
    const sum = balances?.balances.reduce<BigNumber>((acc, x) => {
      acc = acc.plus(x.denom === "unes" ? fromUnesToNes(x.amount) : x.amount);
      return acc;
    }, new BigNumber(0));
    return getFormattedNumber(sum?.toString()) || "-";
  }, [balances]);

  const accountInfo = accountInfoData?.account;

  const txColumns = useTxsColumns();

  const delegationColumns: TableColumn<string, Delegation>[] = [
    {
      key: "validator",
      renderTd: ({ delegation: { validator_address } }) => {
        return <div>{validator_address}</div>;
      },
      title: "Validator",
    },
    {
      key: "delegation",
      renderTd: ({ balance }) => {
        return (
          <div>
            {balance.amount} {balance.denom}
          </div>
        );
      },
      title: "Delegation",
    },
    {
      key: "rewards",
      renderTd: () => {
        return <div>-</div>;
      },
      title: "Rewards",
    },
    {
      key: "action",
      renderTd: () => {
        return <div>-</div>;
      },
      title: "Action",
    },
  ];

  return (
    <AnimateRoute className="flex flex-col p-0 pb-5 pt-2">
      <div
        className="ml-8 flex items-center gap-6 text-2xl font-light text-white max-md:ml-4 max-md:gap-3 max-md:text-xl"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Account
      </div>
      <div className="my-8 border-t border-clay-900"></div>
      <div className="h-full overflow-scroll px-24 max-md:px-0 2xl:px-64">
        <Card className="border-b px-24 max-md:px-9 2xl:px-44">
          {isAccountInfoPending || isBalancesPending ? (
            <div className="flex flex-col items-center justify-center py-8">
              <Spinner className="size-6" />
            </div>
          ) : (
            <>
              <Card.BaseTitle className="pb-0">Account</Card.BaseTitle>
              <Card.Content>
                <div className="flex flex-col">
                  <div className={rowStyles}>
                    <div className="text-white">Type</div>
                    <div className="col-span-5 text-clay-300 max-md:overflow-x-auto">
                      <EllipsisStatic copyable text={accountInfo?.["@type"] || ""} />
                    </div>
                  </div>
                  <div className={`${rowStyles} `}>
                    <div className="text-white max-md:w-[110px]">Account number</div>
                    <div className="col-span-5 text-clay-300">{accountInfo?.account_number}</div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Address</div>
                    <div className="col-span-5 flex items-center justify-between gap-3 text-clay-300">
                      {/* <span>{accountInfo?.address}</span> */}
                      <EllipsisStatic className="w-full" text={accountInfo?.address || ""} />
                      <CopyToClipboard
                        onCopy={() => toaster.success("Copied")}
                        text={accountInfo?.address || ""}
                      >
                        <Icon
                          className="cursor-pointer text-tusk-100 duration-200 hover:text-tusk-100"
                          name="copy"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Pubkey Type</div>
                    <div className="col-span-5 text-clay-300 max-md:overflow-x-auto">
                      {/* <EllipsisStatic text={accountInfo?.address} /> */}

                      <EllipsisStatic
                        className="w-[3/4]"
                        copyable
                        text={accountInfo?.pub_key?.["@type"] || ""}
                      />
                    </div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Pubkey Key</div>
                    <div className="col-span-5 text-clay-300 max-md:overflow-x-auto">
                      {/* {accountInfo?.pub_key?.key} */}
                      <EllipsisStatic copyable text={accountInfo?.pub_key?.key || ""} />
                    </div>
                  </div>
                  <div className={rowStyles}>
                    <div className="text-white">Sequence</div>
                    <div className="col-span-5 text-clay-300">{accountInfo?.sequence}</div>
                  </div>
                  <div className={twMerge(rowStyles, "border-b-0")}>
                    <div className="text-white">Balance</div>
                    <div className="col-span-5 text-clay-300">{sumBalances} NES</div>
                  </div>
                </div>
              </Card.Content>
            </>
          )}
        </Card>

        <div className="flex flex-col gap-8 px-24 pt-8 max-md:px-10 2xl:px-44">
          <div className="rounded-lg bg-clay-900 shadow-md">
            <div className="mb-2 flex items-center justify-between px-3 pb-1 pt-3">
              <h4 className="text-lg font-semibold text-white">Delegations</h4>
              <Button
                onClick={() => {
                  if (account.isConnected) {
                    setIsDelegateOpen(true);
                  } else {
                    setIsProviderOpen(true);
                  }
                }}
              >
                Delegate
              </Button>
            </div>
            {isDelegationsPending ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (delegations?.delegation_responses || []).length > 0 ? (
              <div className="overflow-x-auto">
                <Table
                  className="min-w-min"
                  columns={delegationColumns}
                  data={delegations?.delegation_responses || []}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center pb-6 pt-2 text-center text-corduroy-600">
                No delegations
              </div>
            )}
          </div>

          <div className="h-[420px] rounded-lg bg-clay-900 shadow-md">
            <h4 className="mb-2 px-3 pb-1 pt-3 text-lg font-semibold text-white">Transactions</h4>
            {isTxsLoading ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (
              <div className="h-[350px] overflow-y-auto scrollbar-hide">
                <div className="overflow-x-auto">
                  <Table
                    className="w-full"
                    columns={txColumns}
                    data={lastTxs}
                    onRowClick={(row) =>
                      navigate(`/${chainId}/transactions/${row.tx_response.txhash}`)
                    }
                  />
                </div>
                {hasNextPageTransaction ? (
                  <div className="mt-[10px] flex items-center justify-center">
                    <Button
                      className="mx-auto h-[20px]"
                      isLoading={isFetchingNextPageTransaction}
                      onClick={() => fetchNextPageTransaction()}
                      size="medium"
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center pb-6 pt-2 text-center text-corduroy-600">
                    No data
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="h-[420px] rounded-lg bg-clay-900 shadow-md">
            <h4 className="mb-2 px-3 pb-1 pt-3 text-lg font-semibold text-white">
              Recent Received
            </h4>
            {isTxsPending ? (
              <div className="flex items-center justify-center pb-8 pt-4">
                <Spinner className="size-6" />
              </div>
            ) : (
              <div className="h-[350px] overflow-y-auto scrollbar-hide">
                <div className="w-full overflow-x-auto">
                  <Table className="w-full" columns={txColumns} data={lastReceived} />
                </div>
                {hasNextPageRecent ? (
                  <div className="mt-[10px] flex items-center justify-center">
                    <Button
                      className="mx-auto h-[20px]"
                      isLoading={isFetchingNextPageRecent}
                      onClick={() => fetchNextPageRecent()}
                      size="medium"
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center pb-6 pt-2 text-center text-corduroy-600">
                    No data
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <WalletProviderModal
        isOpen={isProviderOpen}
        onConnected={() => setIsDelegateOpen(true)}
        onOpenChange={setIsProviderOpen}
      />
      {account.data && (
        <TxDialog
          isOpen={isDelegateOpen}
          onOpenChange={setIsDelegateOpen}
          sender={account.data.bech32Address}
          title="Delegate"
          type="delegate"
        />
      )}
    </AnimateRoute>
  );
};
