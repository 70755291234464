import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";
import type { TableColumn } from "shared/ui/Table";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  blocksCount?: number;
};

export const DashboardTableBlocks = ({ blocksCount }: Props) => {
  const navigate = useNavigate();

  const { blocksHistory } = useBlocksStore();

  const { chainId } = useParams();
  const chain = getChainById(chainId);

  const sm = useMinWidthMediaQuery("sm");

  const columns = useMemo(
    (): TableColumn<string, LatestBlock>[] => [
      {
        key: "latestBlock",
        renderTd: (row) => {
          if (sm) {
            return (
              <Link
                className="flex w-40 cursor-pointer items-center gap-3 border-r border-clay-850 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                to={`/${chain.chainId}/blocks/${row.latestBlock}`}
              >
                <Icon className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300" name="box" />
                <div className="flex flex-col">
                  {getFormattedNumber(row.latestBlock)}
                  <span className="text-sm text-clay-500">
                    {timeAgo(DateTime.fromISO(row.time))}
                  </span>
                </div>
              </Link>
            );
          } else {
            return (
              <div className="flex flex-col">
                <div className="flex items-center justify-between pb-2">
                  <Link
                    className="flex w-40 cursor-pointer items-center gap-3 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                    to={`/${chain.chainId}/blocks/${row.latestBlock}`}
                  >
                    <div className="flex flex-col">
                      {getFormattedNumber(row.latestBlock)}
                      <span className="text-sm text-clay-500">
                        {timeAgo(DateTime.fromISO(row.time))}
                      </span>
                    </div>
                  </Link>

                  <div className="flex items-center gap-3">
                    <div
                      className="flex cursor-pointer items-center text-sm font-medium text-white"
                      onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                    >
                      <Icon className="size-8 p-2 text-tusk-100" name="logo" />
                      nesa
                    </div>

                    <div className="h-8 border-l border-clay-850"></div>

                    <div
                      className="cursor-pointer text-center text-sm text-clay-500"
                      onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                    >
                      {row.transactions.length}
                    </div>
                  </div>
                </div>

                <div
                  className="flex min-w-full cursor-pointer truncate text-sm text-clay-500"
                  onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                >
                  <span className="mr-3 text-white">Hash</span>
                  <EllipsisStatic className="w-[300px]" text={row.hash} />
                </div>
              </div>
            );
          }
        },
        tdClassName: sm ? "w-44" : "",
      },
      {
        key: "hash",
        renderTd: (row) => (
          <div
            className="ml-2 flex w-full cursor-pointer truncate text-sm text-clay-500"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            <span className="mr-3 flex-none text-white">Hash</span>
            <EllipsisStatic className="w-full" text={row.hash} />
          </div>
        ),
        tdClassName: "w-[45%] min-w-[400px]",
      },
      {
        key: "logo",
        renderTd: (row) => (
          <div
            className="flex cursor-pointer items-center text-sm font-medium text-white"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />
            nesa
          </div>
        ),
        tdClassName: "w-28",
      },
      {
        key: "txn",
        renderTd: (row) => (
          <div
            className="w-full cursor-pointer text-center text-sm text-clay-500"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {row.transactions.length}
          </div>
        ),
        tdClassName: "w-20",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chain.chainId],
  );

  const blocks = useMemo(() => {
    if (typeof blocksCount === "number") {
      return blocksHistory.slice(0, blocksCount);
    }
    return blocksHistory;
  }, [blocksCount, blocksHistory]);

  return (
    <div className="w-full overflow-x-auto">
      <Table
        className="w-full table-fixed"
        columns={sm ? columns : columns.filter((col) => col.key === "latestBlock")}
        data={blocks}
        hovered
        onRowClick={(row) => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
      />
    </div>
  );
};
