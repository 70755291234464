export const transactionKeys = {
  base: ["transaction"],

  item: (params: Record<string, number | object | string | undefined>) => [
    ...transactionKeys.base,
    "item",
    params,
  ],

  lastReceivedTransactionsInfinite: (
    params: Record<string, number | object | string | undefined>,
  ) => [...transactionKeys.base, "lastReceivedTransactionsInfinite", params],

  lastTransactions: (params: Record<string, number | object | string | undefined>) => [
    ...transactionKeys.base,
    "lastTransactions",
    params,
  ],

  lastTransactionsInfinite: (params: Record<string, number | object | string | undefined>) => [
    ...transactionKeys.base,
    "lastTransactionsInfinite",
    params,
  ],

  totalTransactions: () => [...transactionKeys.base, "totalTransactions"],

  totalTransactionsByPeriod: (params: Record<string, number | object | string | undefined>) => [
    ...transactionKeys.base,
    "total-by-period",
    params,
  ],
} as const;
