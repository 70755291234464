import { useCallback, useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";

import type { Block, ValidatorUnit } from "shared/api/blocks/types";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";

type ChartDataPoint = {
  blockTime: string;
  commitPercent: number;
  committedCount: number;
  height: number;
  missedCount: number;
  precommittedCount: number;
};

type Props = {
  validators: ValidatorUnit[];
};

const getValidatorStatus = (validator: ValidatorUnit, block: Block["block"]) => {
  const sig = block.last_commit?.signatures.find((s) => s.validator_address === validator.base64);

  if (sig) {
    if (sig.block_id_flag === "BLOCK_ID_FLAG_COMMIT") {
      return "committed";
    }
    return "precommitted";
  }

  return "missed";
};

export const BlocksTab = ({ validators }: Props) => {
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);

  const { blocksHistory } = useBlocksStore();

  const latestFilledBlockHeight = useMemo(() => {
    if (chartData.length === 0) return undefined;
    return chartData[chartData.length - 1].height;
  }, [chartData]);

  const handleNewBlock = useCallback(
    (b: Block) => {
      if (!validators?.length) return;

      let committedCount = 0;
      let precommittedCount = 0;
      let missedCount = 0;

      validators.forEach((v) => {
        const status = getValidatorStatus(v, b.block);
        if (status === "committed") {
          committedCount++;
        } else if (status === "precommitted") {
          precommittedCount++;
        } else {
          missedCount++;
        }
      });

      const commitPercent = (committedCount / validators.length) * 100;

      setChartData((prev) => {
        const newPoint: ChartDataPoint = {
          blockTime: b.block.header.time,
          commitPercent: Math.round(commitPercent),
          committedCount,
          height: Number(b.block.header.height),
          missedCount,
          precommittedCount,
        };
        const newData = [...prev, newPoint];
        if (newData.length > 50) {
          return newData.slice(newData.length - 50);
        }
        return newData;
      });
    },
    [validators],
  );

  useEffect(() => {
    const latestBlockData = blocksHistory[0]?.raw;
    if (!latestBlockData) return;
    const latestBlockHeight = Number(latestBlockData.block.header.height);

    if (latestFilledBlockHeight === latestBlockHeight) {
      return;
    }

    handleNewBlock(latestBlockData);
  }, [blocksHistory, handleNewBlock, latestFilledBlockHeight]);
  const sm = useMinWidthMediaQuery("sm");

  return (
    <div className="mx-auto flex w-full max-w-[1100px] flex-col items-center gap-4 rounded-lg bg-clay-1100 pb-6 pt-8">
      <div className="flex w-full items-center justify-between px-10">
        <span className="text-2xl text-white">All services functional</span>
        <span className="text-2xl font-semibold text-green-600">
          {chartData.length && chartData[chartData.length - 1].commitPercent}%
        </span>
      </div>

      <ResponsiveContainer height={240} width="100%">
        <LineChart data={chartData} margin={{ bottom: 30, left: 10, right: 30, top: 10 }}>
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="commitPercentGradient"
              x1="0"
              x2="0"
              y1="0"
              y2="240"
            >
              <stop offset="0%" stopColor="#2DA859" />
              <stop offset="50%" stopColor="#FF9E0D" />
              <stop offset="100%" stopColor="#FF5473" />
            </linearGradient>
          </defs>
          <CartesianGrid horizontal stroke="#191F29" vertical={false} />
          <YAxis
            axisLine={false}
            domain={[0, 100]}
            tick={{
              dx: -40,
              fill: "#4F5662",
              textAnchor: "start",
            }}
            tickLine={false}
          />
          <Line
            activeDot={{
              fill: "#fff",
              r: 5,
              stroke: "#fff",
              strokeWidth: 2,
            }}
            dataKey="commitPercent"
            dot={false}
            stroke="url(#commitPercentGradient)"
            strokeWidth={2}
            type="monotone"
          />

          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload as ChartDataPoint;

                return (
                  <div className="rounded-lg border border-white/10 bg-clay-900 p-3 shadow-sm">
                    <div className="text-white">
                      {DateTime.fromISO(data.blockTime).toFormat("d LLLL yyyy, HH:mm a")}
                    </div>
                    <div className="mt-3 text-xs font-light text-clay-300">Status</div>
                    <div className="mt-1 text-sm text-green-600">
                      Committed: {data.committedCount}
                    </div>
                    <div className="mt-1 text-sm text-yellow-500">
                      Precommitted: {data.precommittedCount}
                    </div>
                    <div className="mt-1 text-sm text-pink-500">Missed: {data.missedCount}</div>
                  </div>
                );
              }
            }}
            cursor={false}
            wrapperStyle={{ outline: "none" }}
          />
        </LineChart>
      </ResponsiveContainer>

      {sm ? (
        <div className="flex w-full flex-col justify-between  gap-4 sm:flex-row sm:items-center sm:px-10">
          <div className="flex items-center max-md:justify-center max-md:gap-1 sm:gap-6">
            <span className="text-white">Legend</span>
            <div className="flex gap-2 ">
              <div className="rounded-lg bg-green-100 px-2.5 py-3 text-sm/none text-green-600 ">
                Committed
              </div>
              <div className="rounded-lg bg-yellow-500/10 px-2.5 py-3 text-sm/none text-yellow-500 ">
                Precommitted
              </div>
              <div className="rounded-lg bg-pink-500/10 px-2.5 py-3 text-sm/none text-pink-500 ">
                Missed
              </div>
            </div>
          </div>
          <span className="text-sm font-light text-clay-300">Updated occur as blocks produced</span>
        </div>
      ) : (
        <div className="flex w-full flex-col justify-center gap-4">
          <div className="flex items-center max-md:justify-center max-md:gap-1">
            <span className="text-white">Legend</span>
            <div className="flex gap-2 ">
              <div className="rounded-lg bg-green-100 px-2.5 py-3 text-sm/none text-green-600 ">
                Committed
              </div>
              <div className="rounded-lg bg-yellow-500/10 px-2.5 py-3 text-sm/none text-yellow-500 ">
                Precommitted
              </div>
              <div className="rounded-lg bg-pink-500/10 px-2.5 py-3 text-sm/none text-pink-500 ">
                Missed
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <span className="text-sm font-light text-clay-300">
              Updated occur as blocks produced
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
