import type { ChainInfo } from "@keplr-wallet/types";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { UseQueryOptions } from "shared/types";

import { nesaTestnet } from "app/config/chains";
import { axiosNodeInstance } from "shared/utils/axiosInstance";

import type { LatestBlock, LatestBlockResponse } from "./types";

import { blocksKeys } from "./queryKeys";

type Params = {
  network?: ChainInfo;
};

export const useLatestBlockQuery = (
  { network = nesaTestnet }: Params = {},
  options: UseQueryOptions<LatestBlock> = {},
  // limit = 10,
) => {
  return useQuery({
    queryFn: async ({ signal }: { signal: AbortSignal }) => {
      const { data } = await axios.get<LatestBlockResponse>(
        `${network.rest}/cosmos/base/tendermint/v1beta1/blocks/latest`,
        {
          // params: {
          //   limit,
          //   skip: pageParam * limit,
          // },
          signal,
        },
      );
      localStorage.setItem("latestBlock", data.block.header.height);
      return {
        hash: data.block_id.hash,
        latestBlock: data.block.header.height,
        proposerAddress: data.block.header.proposer_address,
        raw: data,
        time: data.block.header.time,
        transactions: data.block.data.txs,
        validators: data.block.last_commit?.signatures?.length || 0,
      };
    },
    queryKey: blocksKeys.latest({ rest: network.rest }),
    refetchInterval: 30 * 1000, // 30 seconds
    ...options,
  });
};

export type BlockListItem = {
  hash: string;
  height: number;
  lastBlockHash: string;
  precommitsCount: number;
  proposerAddress: string;
  time: string;
  transNum: number;
  validators: string[];
  validatorsCount: number;
};

export type BlockListResponse = {
  limit: number;
  list: BlockListItem[];
  skip: number;
  total_count: number;
};

type ParamsBlock = {
  limit: number;
  skip: number;
};

export const useLastBlockInfiniteQuery = (params: Pick<ParamsBlock, "limit">) => {
  return useInfiniteQuery({
    getNextPageParam: (last: BlockListItem[], all: BlockListItem[][]) =>
      last.length === params.limit ? all.length : null,
    initialPageParam: 0,
    queryFn: async ({ pageParam = 0, signal }) => {
      const { data } = await axiosNodeInstance.get<BlockListResponse>("/blocks/last", {
        params: {
          limit: params.limit,
          skip: pageParam * params.limit,
        },
        signal,
      });

      return data.list;
    },
    queryKey: blocksKeys.latest(params),
  });
};
