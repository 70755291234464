import { type ComponentProps, forwardRef } from "react";

import { twMerge } from "tailwind-merge";

import { SIZES } from "./styles";
import * as ICONS from "./svg";

export type Name = keyof typeof ICONS;
export type Size = keyof typeof SIZES;

type Props = {
  name: Name;
  safeArea?: "0" | "2" | "4";
  size?: Size;
  svgProps?: ComponentProps<"svg">;
} & ComponentProps<"span">;

export const Icon = forwardRef<HTMLSpanElement, Props>(
  ({ className, name, safeArea = "2", size = "20", svgProps, ...props }, ref) => {
    const IconElement = ICONS[name];

    return (
      <span
        className={twMerge(
          "flex items-center",
          safeArea === "2" && "p-0.5",
          safeArea === "4" && "p-1",
          SIZES[size],
          className,
        )}
        {...props}
        ref={ref}
      >
        <IconElement
          {...svgProps}
          className={twMerge("size-full", svgProps?.className)}
          fill={svgProps?.fill || "currentColor"}
        />
      </span>
    );
  },
);
export const isIconName = (icon?: unknown): icon is Name => {
  return typeof icon === "string" && Object.prototype.hasOwnProperty.call(ICONS, icon || "");
};
