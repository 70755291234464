import { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DateTime } from "luxon";

import type { LatestBlock } from "shared/api/blocks/types";
import type { TableColumn } from "shared/ui/Table";

import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { getValidatorCosmosByAddress } from "features/useValidatorsCosmos";
import { useLastBlockInfiniteQuery } from "shared/api/blocks/useLatestBlockQuery";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";

type Props = {
  blocksCount?: number;
  withHeader?: boolean;
};

type BlockListItem = {
  hash: string;
  height: number;
  lastBlockHash: string;
  precommitsCount: number;
  proposerAddress: string;
  time: string;
  transNum: number;
  validators: string[];
  validatorsCount: number;
};

export const TableBlocks = ({ blocksCount, withHeader = true }: Props) => {
  const navigate = useNavigate();
  const { addBlocks, blocksHistory } = useBlocksStore();
  const sm = useMinWidthMediaQuery("sm");
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const { chainId } = useParams();
  const chain = getChainById(chainId);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useLastBlockInfiniteQuery({
    limit: 20,
  });

  useEffect(() => {
    if (data) {
      // Get the last page of data
      const lastPage = data.pages[data.pages.length - 1];
      const newBlocks = lastPage.map((item: BlockListItem) => ({
        hash: item.hash,
        latestBlock: item.height.toString(),
        proposerAddress: item.proposerAddress,
        time: item.time,
        transNum: item.transNum,
        transactions: [],
        validators: 0,
      }));

      if (newBlocks.length > 0) {
        addBlocks(newBlocks);
      }
    }
  }, [data, addBlocks]);

  useEffect(() => {
    if (!hasNextPage) return;

    const node = loadMoreRef.current;
    if (!node) return;

    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        fetchNextPage();
      }
    });

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [fetchNextPage, hasNextPage]);

  const columns = useMemo(
    (): TableColumn<string, LatestBlock>[] => [
      {
        key: "latestBlock",
        renderTd: (row) => {
          if (sm) {
            return (
              <div
                className="flex cursor-pointer items-center gap-3 text-sm font-light text-white"
                onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
              >
                <Icon className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300" name="box" />
                <div className="flex flex-col">{getFormattedNumber(row.latestBlock)}</div>
              </div>
            );
          } else {
            return (
              <div className="flex flex-col">
                <div className="flex items-center justify-between pb-2">
                  <div
                    className="flex w-40 cursor-pointer items-center gap-3 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                    onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                  >
                    <div className="flex flex-col">
                      {getFormattedNumber(row.latestBlock)}
                      <span className="text-sm text-clay-500">
                        {timeAgo(DateTime.fromISO(row.time))}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div
                      className="flex cursor-pointer items-center text-sm font-medium text-white"
                      onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                    >
                      <Icon className="size-8 p-2 text-tusk-100" name="logo" />
                      nesa
                    </div>

                    <div className="h-8 border-l border-clay-850"></div>

                    <div
                      className="cursor-pointer text-center text-sm text-clay-500"
                      onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                    >
                      {row.transactions.length}
                    </div>
                  </div>
                </div>

                <div
                  className="flex min-w-full cursor-pointer truncate text-sm text-clay-500"
                  onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
                >
                  <span className="mr-3 text-white">Hash</span>
                  <EllipsisStatic className="w-4/5" text={row.hash} />
                </div>
              </div>
            );
          }
        },
        title: sm && withHeader ? "Block" : undefined,
      },
      {
        key: "proposer",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm font-light text-clay-300"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {getValidatorCosmosByAddress(row.proposerAddress)?.moniker || "nesa"}
          </span>
        ),
        title: sm && withHeader ? "Proposer" : undefined,
      },
      {
        key: "time",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm text-corduroy-700"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {timeAgo(DateTime.fromISO(row.time))}
          </span>
        ),
        title: sm && withHeader ? "Time" : undefined,
      },
      {
        key: "hash",
        renderTd: (row) => <span className="text-sm text-clay-300">{row.hash}</span>,
        title: sm && withHeader ? "Hash" : undefined,
      },
      {
        key: "txn",
        renderTd: (row) => (
          <span
            className="cursor-pointer text-sm text-corduroy-700"
            onClick={() => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
          >
            {row.transNum ?? row.transactions.length}
          </span>
        ),
        title: sm && withHeader ? "Txn" : undefined,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chain.chainId, withHeader, sm],
  );

  const blocks = useMemo(() => {
    if (typeof blocksCount === "number") {
      return blocksHistory.slice(0, blocksCount);
    }
    return blocksHistory;
  }, [blocksCount, blocksHistory]);

  return (
    <div className="mt-8 w-full">
      <Table
        className="max-md:w-full"
        columns={sm ? columns : columns.filter((col) => col.key === "latestBlock")}
        data={blocks}
        hovered
        onRowClick={(row) => navigate(`/${chain.chainId}/blocks/${row.latestBlock}`)}
      />
      <div className="flex items-center justify-center py-4" ref={loadMoreRef}>
        {isFetchingNextPage && <Spinner className="size-6" />}
      </div>
    </div>
  );
};
