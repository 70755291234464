import { useState } from "react";
import type { FormEventHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TableBlocks } from "features/TableBlocks";
import { useBlocksStore } from "features/useGlobalLatestBlocks";
import { AnimateRoute } from "shared/ui/AnimateRoute";
import { Icon } from "shared/ui/Icon";
import { Input } from "shared/ui/Input";
import { toaster } from "shared/ui/Toast";

import { BarChart } from "./ui/BarChart";
const txhashRegex = /^\d+$/;

export const Blocks = () => {
  const { blocksHistory } = useBlocksStore();
  const navigate = useNavigate();
  const [hashToSearch, setHashToSearch] = useState("");
  const { chainId } = useParams();
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (txhashRegex.test(hashToSearch.trim())) {
      navigate(`/${chainId}/blocks/${hashToSearch.trim()}`);
      return;
    }

    toaster.error("Tx hash is not valid");
  };

  return (
    <AnimateRoute className="relative flex flex-col p-0 pb-5 pt-2">
      <div
        className="ml-8 flex items-center gap-6 text-2xl font-light text-white  max-md:ml-4 max-md:gap-3 max-md:text-xl"
        onClick={() => navigate(`/`)}
      >
        <Icon
          className="size-6 cursor-pointer rounded-md bg-clay-900 p-1.5 text-clay-300 hover:bg-clay-800"
          name="arrowLeft"
        />
        Blocks
      </div>

      <div className="my-8 border-t border-clay-900"></div>

      <div className=" size-full overflow-x-hidden px-[150px] max-md:px-[20px] 2xl:px-64">
        <BarChart latestBlocks={blocksHistory} />

        <div className="mt-10 flex h-8 w-full items-start justify-between max-md:mt-8">
          <form
            className="w-[220px] max-w-full select-none text-center text-2xl/none text-primary-900 md:text-4xl"
            onSubmit={handleSubmit}
          >
            <Input
              className="w-full"
              classNameInput="text-white resize-none"
              classNameInputWrapper="bg-clay-900 border-0"
              onChange={(e) => setHashToSearch(e.target.value)}
              placeholder="Block by block number"
              startSlot={<Icon className="text-clay-100" name="search" />}
              value={hashToSearch}
            />
          </form>
        </div>
        <TableBlocks />
      </div>
      {/* <FooterSection></FooterSection> */}
    </AnimateRoute>
  );
};
