import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { fromBase64 } from "@cosmjs/encoding";
import { decodeTxRaw } from "@cosmjs/proto-signing";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { hashTx } from "shared/helpers/decode";
import { getChainById } from "shared/helpers/getChainById";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { Table } from "shared/ui/Table";

type Props = {
  transactionsCount?: number;
  txs: { blockHeight?: string; tx: TransactionQueryResponse | string }[];
  withHeader?: boolean;
};

type TransactionColumn = {
  blockHeight?: string;
  hash: string;
  memo: string;
  message: string;
};

export const TableTransactions = ({ transactionsCount, txs, withHeader = true }: Props) => {
  const { chainId } = useParams();
  const navigate = useNavigate();

  const chain = getChainById(chainId);
  const sm = useMinWidthMediaQuery("sm");

  const transactions = useMemo(() => {
    const transactions = transactionsCount ? txs.slice(0, transactionsCount) : txs;
    return transactions.map(({ blockHeight, tx }): TransactionColumn => {
      const decoded = typeof tx === "string" ? decodeTxRaw(fromBase64(tx)) : null;
      const body = typeof tx !== "string" ? tx.tx.body : null;

      const hash = typeof tx === "string" ? hashTx(fromBase64(tx)) : tx.txhash;

      return {
        blockHeight,
        hash: hash,
        memo: decoded?.body.memo || body?.memo || "-",
        message: getTxMessage(
          (decoded?.body.messages || body?.messages) as TxResponse["tx"]["body"]["messages"],
        ),
      };
    });
  }, [txs, transactionsCount]);

  const transactionsColumns = useMemo(() => {
    const desktopColumns: TableColumn<keyof TransactionColumn, TransactionColumn>[] = [
      {
        key: "blockHeight" as keyof TransactionColumn,
        renderTd: ({ blockHeight }: TransactionColumn) => (
          <Link
            className="flex w-32 cursor-pointer items-center gap-3 border-r border-clay-850 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
            to={`/${chain.chainId}/blocks/${blockHeight}`}
          >
            <Icon className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300" name="box" />
            <div className="flex flex-col font-light">{getFormattedNumber(blockHeight)}</div>
          </Link>
        ),
        thClassName: "w-36 !rounded-none",
        title: withHeader ? "Block" : undefined,
      },
      {
        key: "hash",
        renderTd: (row) => (
          <Link
            className="ml-2 truncate text-sm text-clay-300 hover:brightness-90"
            to={`/${chain.chainId}/transactions/${row.hash}`}
          >
            {/* {row.hash} */}
            <EllipsisStatic copyable text={row.hash} />
          </Link>
        ),
        thClassName: "!rounded-none",
        title: withHeader ? "Hash" : undefined,
      },
      {
        key: "message",
        renderTd: (row) => <div className="truncate">{row.message}</div>,
        thClassName: "w-48 !rounded-none",
        title: withHeader ? "Message" : undefined,
      },
      {
        key: "memo" as keyof TransactionColumn,
        renderTd: (row: TransactionColumn) => row.memo,
        thClassName: "w-24 !rounded-none",
        title: withHeader ? "Memo" : undefined,
      },
    ];

    const mobileColumns: TableColumn<keyof TransactionColumn, TransactionColumn>[] = [
      {
        key: "message",
        renderTd: (row) => (
          <div className="flex flex-col">
            <div className="flex items-center justify-between pb-2">
              <div
                className="flex cursor-pointer items-center gap-3 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
              >
                <div className="flex w-[30px] items-center justify-center">
                  <Icon className=" size-6 text-tusk-100" name="logo" />
                </div>
                <div className="flex flex-col">{row.message}</div>
              </div>
            </div>

            <div
              className="flex w-full cursor-pointer truncate text-sm text-clay-500"
              onClick={() => navigate(`/${chain.chainId}/transactions/${row.hash}`)}
            >
              <span className="mr-3 w-[30px] flex-none text-white">Hash</span>
              <EllipsisStatic className="w-full" text={row.hash} />
            </div>
          </div>
        ),
        tdClassName: "w-full",
      },
    ];

    return sm ? desktopColumns : mobileColumns;
  }, [withHeader, chain, sm, navigate]);

  return (
    <>
      <div className="w-full overflow-x-auto ">
        <Table
          className="w-full table-fixed"
          columns={transactionsColumns}
          data={transactions || []}
          hovered
        />
      </div>
    </>
  );
};
