import { useCallback, useEffect, useId, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { Area, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { theme } from "app/theme";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getFormattedNumberWithUnit } from "shared/helpers/getFormattedNumberWithUnit";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";

import { Icon } from "../Icon";
import { Select } from "../Select";

type ChartDataItem = {
  date: string;
  sessions: number;
  volume: number;
};

type Props = {
  data: {
    [date: string]: {
      sessions: number;
      volume: number;
    };
  };
  isEmptyData?: boolean;
  onScaleChange: (scale: string) => void;
  scale: string;
};

export const LineChart = ({ data, isEmptyData, onScaleChange, scale }: Props) => {
  const [yAxisWidth, setYAxisWidth] = useState(20);

  const xl2 = useMinWidthMediaQuery("2xl");

  const axisFontSize = xl2 ? 10 : 8;

  const gradId = useId();

  const timeScale = [
    { label: "1 Day", value: "1" },
    { label: "1 Week", value: "7" },
    { label: "1 Month", value: "30" },
  ];

  const timeScaleValues = timeScale.map((item) => item.value);

  const incrementTimeScale = () => {
    const currentIndex = timeScaleValues.indexOf(scale);
    if (currentIndex < timeScaleValues.length - 1) {
      const newScale = timeScaleValues[currentIndex + 1];
      onScaleChange(newScale);
    }
  };

  const decrementTimeScale = () => {
    const currentIndex = timeScaleValues.indexOf(scale);
    if (currentIndex > 0) {
      const newScale = timeScaleValues[currentIndex - 1];
      onScaleChange(newScale);
    }
  };

  const handleScaleChange = (newScale: string) => {
    onScaleChange(newScale);
  };

  const handleReset = () => {
    onScaleChange("1");
  };

  const aggregateData = useCallback((data: ChartDataItem[], interval: number): ChartDataItem[] => {
    const aggregatedData: ChartDataItem[] = [];

    // 从后往前走，每次取 interval 个数据
    for (let end = data.length; end > 0; end -= interval) {
      const start = Math.max(end - interval, 0);
      const slice = data.slice(start, end);

      const sumVolume = slice.reduce((acc, curr) => acc + curr.volume, 0);
      const sumSessions = slice.reduce((acc, curr) => acc + curr.sessions, 0);

      // 这里依然用分组段的最后一个元素日期
      const date = slice[slice.length - 1]?.date || "";

      aggregatedData.push({
        date,
        sessions: sumSessions,
        volume: sumVolume,
      });
    }

    // 由于是倒着分，aggregatedData 里目前是从最新分组到最早分组，这里反转一下
    return aggregatedData.reverse();
  }, []);

  const filteredData: ChartDataItem[] = useMemo(() => {
    const result = Object.entries(data).map(([date, { sessions, volume }]) => {
      return {
        date,
        sessions,
        volume,
      };
    });

    switch (scale) {
      case "1":
        return result;
      case "7":
        return aggregateData(result, 7);
      case "30":
        return aggregateData(result, 30);
      default:
        return result;
    }
  }, [data, scale, aggregateData]);

  const tickFormatter = useCallback(
    (balance: number) => {
      const label = `${balance}`;
      const width = label.length * axisFontSize;
      setTimeout(() => setYAxisWidth((prev) => (prev > width ? prev : width)));

      return getFormattedNumber(label);
    },
    [axisFontSize],
  );

  return (
    <div className="relative size-full">
      <ResponsiveContainer>
        <ComposedChart
          className="bg-black px-[5px]"
          data={filteredData}
          margin={{ bottom: 0, left: 8, right: 8, top: 24 }}
        >
          <YAxis
            allowDataOverflow={false}
            allowDecimals={false}
            axisLine={false}
            domain={isEmptyData ? [0, 4] : [0, (dataMax: number) => dataMax * 12]}
            hide={true}
            minTickGap={0}
            tick={{
              color: theme.colors.corduroy[700],
              fontFamily: "Inter",
              fontSize: axisFontSize,
              fontWeight: 600,
            }}
            tickFormatter={tickFormatter}
            tickLine={false}
            width={yAxisWidth}
            yAxisId="left"
          />

          <YAxis
            domain={[(dataMin: number) => dataMin + 10, (dataMax: number) => dataMax + 10]}
            hide={true}
            orientation="right"
            padding={{ bottom: 55 }}
            yAxisId="right"
          />

          <XAxis
            allowDataOverflow={false}
            axisLine={false}
            dataKey="date"
            hide={true}
            interval={1}
            minTickGap={0}
            tick={({ payload, x, y }: AxisTickProps) => {
              const date = DateTime.fromISO(payload.value);

              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    dy={12}
                    fill={theme.colors.corduroy[700]}
                    fontFamily="Inter"
                    fontSize={axisFontSize}
                    fontWeight={600}
                    textAnchor="middle"
                    x={0}
                    y={0}
                  >
                    {date.toLocaleString(DateTime.DATE_SHORT)}
                  </text>
                </g>
              );
            }}
            tickLine={false}
          />

          <defs>
            <linearGradient
              gradientUnits="objectBoundingBox"
              id={gradId}
              x1="1"
              x2="1"
              y1="0"
              y2="1"
            >
              <stop stopColor="#E2F2B4" stopOpacity="0.1" />
              <stop offset="1" stopColor="#080D161A" stopOpacity="0.1" />
            </linearGradient>
          </defs>

          <Area
            activeDot={{
              fill: theme.colors.white,
              height: 8,
              stroke: theme.colors.tusk[100],
              strokeWidth: 2,
              width: 8,
            }}
            dataKey="volume"
            fill={`url(#${gradId})`}
            stroke={theme.colors.tusk[100]}
            strokeWidth={1}
            type="linear"
            yAxisId="right"
          />

          <Tooltip
            content={({ active, payload }) => {
              const data = payload?.[0]?.payload as ChartDataItem | undefined;
              if (!data || !active) return null;
              return (
                <div className="relative z-30 flex w-44 flex-col gap-3 rounded-lg border border-white/10 bg-white/5 p-2">
                  <div className="absolute inset-0 -z-10 rounded-lg backdrop-blur-xl"></div>

                  <div className="text-xs font-light text-white">
                    {new Date(
                      new Date(data.date).setDate(new Date(data.date).getDate() + 1),
                    ).toLocaleDateString(undefined, { dateStyle: "long" })}
                    {/* {`${DateTime.fromISO(data.date).toFormat("d LLLL yyyy")}`} */}
                  </div>

                  <div className="grid grid-cols-2 items-center justify-between gap-2">
                    <div>
                      <div className="text-xs font-light text-clay-300">Amount</div>
                      <div className="mt-1 text-lg/none font-normal text-white">
                        {typeof data.sessions === "number"
                          ? `$${getFormattedNumberWithUnit(0.85 * data.volume)}`
                          : "0"}
                      </div>
                    </div>

                    <div>
                      <div className="text-xs font-light text-clay-300">Requests</div>
                      <div className="mt-1 text-lg/none font-normal text-white">
                        {typeof data.sessions === "number"
                          ? getFormattedNumberWithUnit(20 * data.sessions)
                          : "0"}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center text-xs font-medium text-white">
                    <Icon
                      className="mr-2 size-6 rounded-full bg-clay-900 p-1.5 text-tusk-100"
                      name="logo"
                    />
                    NESA AI&nbsp;<span className="text-clay-300">($NES)</span>
                  </div>
                </div>
              );
            }}
            cursor={false}
            wrapperStyle={{ outline: "none" }}
          />
        </ComposedChart>
      </ResponsiveContainer>

      <div className="absolute bottom-6 left-5 flex gap-1.5">
        <div
          className="flex size-7 cursor-pointer items-center justify-center rounded-lg bg-white/5 text-sm/none text-white hover:bg-white/20"
          onClick={decrementTimeScale}
        >
          +
        </div>

        <div
          className="flex size-7 cursor-pointer items-center justify-center rounded-lg bg-white/5 text-sm/none text-white hover:bg-white/20"
          onClick={incrementTimeScale}
        >
          -
        </div>

        <div
          className="flex h-7 cursor-pointer items-center justify-center rounded-lg bg-white/5 px-2.5 text-sm/none text-white hover:bg-white/20"
          onClick={handleReset}
        >
          Reset
        </div>
      </div>

      <div className="absolute bottom-6 right-5 flex h-7 items-center rounded-lg bg-white/5 py-2.5 pl-2.5">
        <div className="h-fit border-r border-white/10 bg-transparent pr-2 text-xs text-clay-300">
          Timeframe
        </div>

        <Select
          className="w-24 bg-transparent !pl-2 text-white inner-border-0"
          onValueChange={handleScaleChange}
          value={scale}
        >
          <Select.Content className="bg-clay-900 inner-border-clay-900">
            {timeScale.map((el) => (
              <Select.Item className="text-white hover:bg-clay-800" key={el.value} value={el.value}>
                {el.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </div>
    </div>
  );
};

type AxisTickProps = {
  payload: { value: string };
  x: number;
  y: number;
};
