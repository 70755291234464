// /cosmos/tx/v1beta1/txs/9EDD95C240E4682011CCFDC543DE490011A9971A170E86D0B914358183FAFF12

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import type { Network } from "shared/helpers/getBaseEndpoints";
import type { UseQueryOptions } from "shared/types";

import { getBaseEndpoints } from "shared/helpers/getBaseEndpoints";
import { axiosNodeInstance } from "shared/utils/axiosInstance";

import type { GetTxByHashResponse, GetTxByHashResponseAPI } from "./types";

import { transactionKeys } from "./queryKeys";

type Params = {
  hash: string;
  network?: Network;
};

export const useTransactionByHashQuery = (
  { hash, network = "testnet" }: Params,
  options: UseQueryOptions<GetTxByHashResponse> = {},
) => {
  const baseUrl = getBaseEndpoints(network);
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axios.get<GetTxByHashResponse>(
        `${baseUrl}/cosmos/tx/v1beta1/txs/${hash}`,
        { signal },
      );

      return data;
    },
    queryKey: transactionKeys.item({ baseUrl, hash }),
    ...options,
  });
};

export const useTransactionByHashQueryAPI = (
  { hash, network = "testnet" }: Params,
  options: UseQueryOptions<GetTxByHashResponse> = {},
) => {
  const baseUrl = getBaseEndpoints(network);
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosNodeInstance.get<GetTxByHashResponseAPI>(
        `/transactions/tx/${hash}`,
        { signal },
      );
      return data?.data;
    },
    queryKey: [...transactionKeys.item({ baseUrl, hash }), "api"],
    ...options,
  });
};
