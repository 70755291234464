import { WalletType, useSuggestChainAndConnect } from "graz";

import { nesaTestnet } from "app/config/chains";
import { Modal } from "shared/ui/Modal";
import { toaster } from "shared/ui/Toast";

import cosmostationSrc from "./assets/cosmostation.svg";
import keplrSmLogo from "./assets/keplrSmLogo.svg";
import leapSrc from "./assets/leap.svg";
import metamaskSrc from "./assets/metamask.svg";

interface Props {
  isOpen: boolean;
  onConnected?: () => void;
  onOpenChange: (open: boolean) => void;
}

const WALLETS = [
  { image: metamaskSrc, title: "MetaMask", walletType: WalletType.METAMASK_SNAP_LEAP },
  { image: keplrSmLogo, title: "Keplr", walletType: WalletType.KEPLR },
  { image: leapSrc, title: "Leap", walletType: WalletType.LEAP },
  { image: cosmostationSrc, title: "Cosmostation", walletType: WalletType.COSMOSTATION },
];

export const WalletProviderModal = ({ isOpen: open, onConnected, onOpenChange }: Props) => {
  const isKeplrWalletInstalled = !!window.keplr;

  const { suggestAndConnectAsync } = useSuggestChainAndConnect();

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-fit">
        {/* <Modal.CloseButton /> */}
        <Modal.Title className="mb-5 mt-2 text-center">
          <div className="text-xl font-medium">Connect your Wallet</div>
          <div className="mt-2 text-sm font-light text-clay-300">
            Choose how you want to connect. There are several wallet providers.
          </div>
        </Modal.Title>

        <div className="grid w-full grid-cols-2 gap-1">
          {WALLETS.map(({ image, title, walletType }) => {
            const isKeplr = walletType === WalletType.KEPLR;
            return (
              <div
                className="flex cursor-pointer flex-col items-center gap-4 rounded-lg bg-clay-1100 p-4 transition-colors hover:bg-clay-800"
                key={walletType}
                onClick={async () => {
                  try {
                    if (isKeplr && !window.keplr) {
                      toaster.error(`You don't have Keplr wallet installed or enabled`);
                      return;
                    }

                    await suggestAndConnectAsync({
                      chainInfo: nesaTestnet,
                      walletType,
                    });

                    onConnected?.();
                    onOpenChange(false);
                  } catch (e) {
                    toaster.error("Something went wrong");
                  }
                }}
              >
                <img className="w-7 overflow-hidden rounded-md" src={image} />

                <div className="text-sm font-light text-white">
                  {title}
                  {isKeplr}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Content>
    </Modal>
  );
};
