import type { ChainInfo } from "@keplr-wallet/types";

import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { DateTime } from "luxon";

import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useLastTransaction";
import type { TableColumn } from "shared/ui/Table";

import { useLastTransactionInfiniteQuery } from "shared/api/transactions/useLastTransaction";
import { getFormattedNumber } from "shared/helpers/getFormattedNumber";
import { getTxMessage } from "shared/helpers/getTxMessage";
import { timeAgo } from "shared/helpers/timeAgo";
import { useMinWidthMediaQuery } from "shared/hooks/useMediaQuery";
import { Button } from "shared/ui/Button";
import { EllipsisStatic } from "shared/ui/EllipsisStatic";
import { Icon } from "shared/ui/Icon";
import { Spinner } from "shared/ui/Spinner";
import { Table } from "shared/ui/Table";

type Props = {
  network: ChainInfo;
};

export const TransactionListContent = ({ network }: Props) => {
  const navigate = useNavigate();
  const sm = useMinWidthMediaQuery("sm");

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useLastTransactionInfiniteQuery({
      limit: 20,
    });

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const lastTransactions = useMemo(() => (data ? data.pages.flat() : null), [data]);

  useEffect(() => {
    if (!hasNextPage) return;

    const node = loadMoreRef.current;
    if (!node) return;

    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        fetchNextPage();
      }
    });

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [fetchNextPage, hasNextPage]);

  const desktopColumns: TableColumn<string, TransactionQueryResponse>[] = useMemo(
    () => [
      {
        key: "height",
        renderTd: (row) => {
          return (
            <div
              className="flex cursor-pointer items-center gap-3 text-white"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              <Icon
                className="size-8 rounded-lg bg-clay-850 p-2 text-clay-300"
                name="arrowLeftRight"
              />

              <div className="flex flex-col font-light">
                {row.tx_response.height}
                <span className="whitespace-nowrap text-sm text-clay-500">
                  {row.tx_response?.timestamp
                    ? `${timeAgo(DateTime.fromISO(row.tx_response.timestamp))}`
                    : "-"}
                </span>
              </div>
            </div>
          );
        },
        title: "Block",
      },
      {
        key: "hash",
        renderTd: (row) => {
          return (
            <div className="w-full min-w-[33%] text-clay-300">
              <EllipsisStatic text={row.txhash} />
            </div>
          );
        },
        title: "Tx Hash",
      },
      {
        key: "message",
        renderTd: (row) => {
          return (
            <div
              className="max-w-48 cursor-pointer"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {getTxMessage(row.tx.body.messages as TxResponse["tx"]["body"]["messages"])}
            </div>
          );
        },
        title: "Type",
      },
      {
        key: "fees",
        renderTd: (row) => {
          const amount = row.tx.auth_info.fee?.amount?.[0];
          if (!amount) return null;
          return (
            <div
              className="cursor-pointer whitespace-nowrap px-5"
              onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
            >
              {getFormattedNumber(amount.amount)} {amount.denom}
            </div>
          );
        },
        title: "Fee",
      },
    ],
    [navigate, network.chainId],
  );

  const mobileColumns: TableColumn<string, TransactionQueryResponse>[] = useMemo(
    () => [
      {
        key: "content",
        renderTd: (row) => {
          return (
            <div className="flex flex-col">
              <div className="flex items-center justify-between pb-2">
                <div
                  className="flex cursor-pointer items-center gap-3 text-sm font-normal text-white transition-all duration-200 hover:brightness-90"
                  onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
                >
                  <div className="flex flex-col">
                    {getTxMessage(row.tx.body.messages as TxResponse["tx"]["body"]["messages"])}
                    <span className="text-sm text-clay-500">
                      {row.tx_response?.timestamp
                        ? `${timeAgo(DateTime.fromISO(row.tx_response.timestamp))}`
                        : "-"}
                    </span>
                  </div>
                </div>

                <div
                  className="cursor-pointer"
                  onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
                >
                  <div className="flex w-fit items-center text-sm text-white">
                    {row.tx.auth_info.fee?.amount?.[0] && (
                      <>
                        <Icon className="mr-2 size-8 p-2 text-tusk-100" name="logo" />
                        {getFormattedNumber(row.tx.auth_info.fee.amount[0].amount)}{" "}
                        {row.tx.auth_info.fee.amount[0].denom}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="flex w-full cursor-pointer text-sm text-clay-500"
                onClick={() => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
              >
                <span className="mr-3 flex-none text-white">Hash</span>
                <EllipsisStatic className="w-full" text={row.txhash} />
              </div>
            </div>
          );
        },
      },
    ],
    [navigate, network.chainId],
  );

  const columns = useMemo(
    () => (!sm ? mobileColumns : desktopColumns),
    [sm, mobileColumns, desktopColumns],
  );

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center py-8">
        <Spinner className="size-6" />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col max-md:overflow-x-auto">
      <div className="mb-4 size-full overflow-auto">
        <Table
          className="w-full max-md:w-full 2xl:table-auto"
          columns={columns}
          data={lastTransactions || []}
          onRowClick={(row) => navigate(`/${network.chainId}/transactions/${row.txhash}`)}
        />
      </div>

      <div className="flex items-center justify-center py-4" ref={loadMoreRef}>
        {isFetchingNextPage && <Spinner className="size-6" />}
      </div>
    </div>
  );
};
