import type { TxResponse } from "shared/api/transactions/types";
import type { TransactionQueryResponse } from "shared/api/transactions/useAccountTxsQuery";

type MessageType =
  | TransactionQueryResponse["tx"]["body"]["messages"]
  | TxResponse["tx"]["body"]["messages"];

export const getTxMessage = (messages: MessageType) => {
  if (messages.length === 0) return "unknown";

  const firstMsgType = ((messages[0] as { ["@type"]: string })["@type"] ||
    ("typeUrl" in messages[0] && messages[0].typeUrl) ||
    "unknown") as string;

  const formattedType = firstMsgType
    .substring(firstMsgType.lastIndexOf(".") + 1)
    .replace("Msg", "");

  if (messages.length === 1) {
    return formattedType;
  } else if (messages.length === 2) {
    return `${formattedType} + 1 other`;
  } else {
    return `${formattedType} + ${messages.length - 1} others`;
  }
};
