import type { Id, ToastOptions } from "react-toastify";

import { toast } from "react-toastify";

import { twMerge } from "tailwind-merge";

import { theme } from "app/theme";

import { Icon } from "../Icon";
import successIcon from "./assets/check-check.svg";
import errorIcon from "./assets/circle-x.svg";
import infoIcon from "./assets/megaphone.svg";
import warningIcon from "./assets/octagon-alert.svg";

type ToastType = "error" | "info" | "success" | "warning";
const toastConfig: Record<ToastType, { color: string; iconSrc: string }> = {
  error: { color: theme.colors.red[900], iconSrc: errorIcon },
  info: { color: theme.colors.primary[800], iconSrc: infoIcon },
  success: { color: theme.colors.green[600], iconSrc: successIcon },
  warning: { color: theme.colors.yellow[500], iconSrc: warningIcon },
};

type Props = {
  actionText?: string;
  closeToast?: ToastOptions["onClose"];
  onActionClick?: () => void;
  text: string;
  type?: ToastType;
};

export const Toast = ({ actionText, closeToast, onActionClick, text, type = "info" }: Props) => {
  const conf = toastConfig[type];

  return (
    <div className="flex w-full gap-3 overflow-hidden rounded-lg bg-clay-800">
      <div
        className={twMerge(
          "pointer-events-none absolute -left-4 top-[-80%] h-28 w-24 select-none bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]",
          type === "success" && "from-green-600/30 from-0% to-60%",
          type === "error" && "from-red-900/30 from-0% to-60%",
          type === "info" && "from-transparent/30 from-0% to-60%",
          type === "warning" && "from-yellow-500/30 from-0% to-60%",
        )}
      ></div>

      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-4">
          <img className="pointer-events-none size-[16px] select-none" src={conf.iconSrc} />

          <div className="flex flex-col gap-1">
            <div className="font-body text-sm/5 font-light text-white">{text}</div>
            {!!onActionClick && (
              <button
                className="m-0 flex items-start p-0 font-body text-xs font-light text-clay-300 transition-colors hover:text-clay-100"
                onClick={onActionClick}
              >
                {actionText || "Action"}
              </button>
            )}
          </div>
        </div>

        <div>
          <Icon
            className="size-5 cursor-pointer text-clay-300 transition-colors hover:text-clay-100"
            name="close"
            onClick={() => closeToast?.(undefined)}
          />
        </div>
      </div>
    </div>
  );
};

type Options = Omit<Props, "closeToast" | "text" | "type">;

export const toaster = (text: string, props: Options, toastProps: ToastOptions): Id =>
  toast(<Toast text={text} {...props} />, {
    ...toastProps,
    hideProgressBar: true,
    style: {
      padding: 0,
    },
  });

toaster.success = (text: string, props: Options = {}, toastProps: ToastOptions = {}): Id =>
  toast.success(<Toast text={text} type="success" {...props} />, {
    ...toastProps,
    hideProgressBar: true,
  });

toaster.info = (text: string, props: Options = {}, toastProps: ToastOptions = {}): Id =>
  toast.info(<Toast text={text} type="info" {...props} />, {
    ...toastProps,
    hideProgressBar: true,
  });

toaster.error = (text: string, props: Options = {}, toastProps: ToastOptions = {}): Id =>
  toast.error(<Toast text={text} type="error" {...props} />, {
    ...toastProps,
    hideProgressBar: true,
  });

toaster.warning = (text: string, props: Options = {}, toastProps: ToastOptions = {}): Id =>
  toast.warning(<Toast text={text} type="warning" {...props} />, {
    ...toastProps,
    hideProgressBar: true,
  });
